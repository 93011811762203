.
<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="product === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-e-commerce-shop' }"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card
      v-if="product"
      no-body
    >
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="`https://res.cloudinary.com/aftercode/${product.image}`"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="7"
          >
            <!-- Product Name -->
            <h4>{{ product.name }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <b-link class="company-name">
                {{ product.reference }}
              </b-link>
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">
                ${{ product.sales_price_ttc }}
              </h4>
            </div>

            <!-- Avability -->
            <b-card-text
              v-if="product.quantity > 0"
            >Disponible -
              <span class="text-success">Disponible en stock</span></b-card-text>
            <b-card-text
              v-else
            >Non Disponible -
              <span class="text-danger">Repture de stock</span></b-card-text>

            <!-- Product Description -->
            <b-card-text>{{ product.description }}</b-card-text>
            <hr>
            <div class="product-color-options">
              <h6>Stock : {{ product.quantity }}</h6>
            </div>
            <hr>

            <!-- Colors -->
            <div class="product-color-options">
              <h6>Catégorie : {{ product.category }}</h6>
            </div>

            <hr>
            <div class="product-color-options">
              <h6>Prix achat : {{ product.purchase_price_ttc }}</h6>
            </div>

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                :to="{ name: 'edit-product', params: { id: product.id } }"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axiosIns from '@/libs/axios'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BAlert,

    // SFC
  },
  data() {
    return { product: '' }
  },

  created() {
    this.getProduct()
  },
  methods: {
    async getProduct() {
      const { data } = await axiosIns.get(`/products/${this.$route.params.id}`)
      this.product = { ...data }
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
